import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify(
	{
		icons:
		{
			iconfont: 'mdi'
		},
		theme:
		{
			themes:
			{
				light:
				{
					primary: '#003167',
					secondary: '#0094d5',
					success: '#38a962',
					error: '#e30054',
					info: '#0094d5',
					link: '#0094d5',
					
					dark: '#001c31',
					light: '#ffffff',
					//background: '#ffffff'
				}
			}
		}
	}
);